<template>
  <BSidebar
    id="sidebar-backdrop"
    ref="sidebarBackDrop"
    backdrop-variant="dark"
    no-close-on-backdrop
    no-close-on-esc
    no-header
    backdrop
    shadow
    right
    width="450px"
  >
    <div class="p-2">
      <div class="text_edit flex items-center justify-between">
        <div class="text-[#333]">
          <b class="text-2xl">Edit Kontak</b>
        </div>
        <div
          class="icon-close"
          @click="cancelEdit"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
            alt="icon close"
          >
        </div>
      </div>
      <hr>
      <div class="contect-edit-contact">
        <div class="col-nama mb-1">
          <label for="nama">Nama</label>
          <b-form-input
            v-model="nama"
            placeholder="Masukkan Nama Contact"
            type="text"
          />
        </div>
        <div class="col-whatsapp mb-1">
          <label for="whatsapp">Nomor WhatsApp</label>
          <b-input-group
            size="lg"
            prepend="+62"
          >
            <b-form-input
              v-model="whatsapp"
              placeholder="Masukkan whatsapp Contact"
              type="number"
            />
          </b-input-group>
        </div>
        <div class="col-lokasi mb-1">
          <label for="lokasi">Lokasi</label>
          <b-form-input
            v-model="lokasi"
            placeholder="Masukkan lokasi"
            type="text"
          />
        </div>
        <div
          v-if="!isHidden1"
          class="col-var1 mb-1"
        >
          <label for="var1">Variabel 1</label>
          <div class="flex items-center gap-[16px]">
            <b-form-input
              v-model="var1"
              placeholder="Masukkan Variabel 1"
              type="text"
              @keypress="isAlphabet($event)"
            />
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/close-circle.svg"
              alt="icon close"
              class="icon-close"
              @click="closeSpace"
            >
          </div>
        </div>
        <div
          v-if="!isHidden2"
          class="col-var2 mb-1"
        >
          <label for="var2">Variabel 2</label>
          <div class="flex items-center gap-[16px]">
            <b-form-input
              v-model="var2"
              placeholder="Masukkan Variabel 2"
              type="text"
              @keypress="isAlphabet($event)"
            />
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/close-circle.svg"
              alt="icon close"
              class="icon-close"
              @click="closeSpace"
            >
          </div>
        </div>
        <div
          v-if="!isHidden3"
          class="col-var3 mb-1"
        >
          <label for="var1">Variabel 3</label>
          <div class="flex items-center gap-[16px]">
            <b-form-input
              v-model="var3"
              placeholder="Masukkan Variabel 3"
              type="text"
              @keypress="isAlphabet($event)"
            />
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/close-circle.svg"
              alt="icon close"
              class="icon-close"
              @click="closeSpace"
            >
          </div>
        </div>
        <div
          v-if="variableCount < maxVariableCount"
          class="add-variabel flex justify-center my-3"
        >
          <b-button
            variant="primary"
            @click="addSpace"
          >
            <div class="flex items-center gap-[10px]">
              <span>+</span>
              <span>Tambah Variabel</span>
            </div>
          </b-button>
        </div>
      </div>
      <hr class="mt-[50px]">
      <div class="flex items-center justify-end">
        <div>
          <b-button
            :variant="isDisabled ? 'secondary' : 'primary'"
            :disabled="isDisabled"
            @click="saveAndHideSidebar"
          >Simpan</b-button>
        </div>
      </div>
    </div>
  </BSidebar>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { isAlphabet } from '@/libs/helpers'

export default {
  name: 'ModalEditContact',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    getAllContact: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      nama: '',
      whatsapp: '',
      lokasi: '',
      var1: '',
      var2: '',
      var3: '',
      isHidden1: true,
      isHidden2: true,
      isHidden3: true,
      variableCount: 0,
      maxVariableCount: 3,
      originalData: {},
      isAlphabet,
    }
  },
  computed: {
    isDisabled() {
      return (
        this.nama === this.originalData.name
    && this.whatsapp === this.originalData.phone.replace(/^0+/, '')
    && this.lokasi === this.originalData.address
    && (this.var1 === this.originalData.var1 || this.var1.trim() === '')
    && (this.var2 === this.originalData.var2 || this.var2.trim() === '')
    && (this.var3 === this.originalData.var3 || this.var3.trim() === '')
      )
    },
  },
  watch: {
    data: {
      handler(newData) {
        this.originalData = { ...newData }
        this.nama = newData.name || ''
        this.whatsapp = newData.phone.replace(/^0+/, '') || ''
        this.lokasi = newData.address || ''
        this.var1 = newData.var1 || ''
        this.var2 = newData.var2 || ''
        this.var3 = newData.var3 || ''
      },
      immediate: true,
    },
  },
  mounted() {
    this.originalData = { ...this.data }
    this.nama = this.data.name || ''
    this.whatsapp = this.data.phone.replace(/^0+/, '') || ''
    this.lokasi = this.data.address || ''
    this.var1 = this.data.var1 || ''
    this.var2 = this.data.var2 || ''
    this.var3 = this.data.var3 || ''
  },
  methods: {
    saveAndHideSidebar() {
      this.confirmEdit()
      this.$refs.sidebarBackDrop.hide()
    },
    cancelEdit() {
      this.nama = this.data.name || ''
      this.whatsapp = this.data.phone.replace(/^0+/, '') || ''
      this.lokasi = this.data.address || ''
      this.var1 = this.data.var1 || ''
      this.var2 = this.data.var2 || ''
      this.var3 = this.data.var3 || ''
      this.$refs.sidebarBackDrop.hide()
    },
    async confirmEdit() {
      const idUpdate = this.data.id
      const params = {
        name: this.nama,
        phone: `0${this.whatsapp}`,
        address: this.lokasi,
        var1: this.var1,
        var2: this.var2,
        var3: this.var3,
      }
      await this.$http_new.put(`/komchat-api/api/v1/contact/update/${idUpdate}`, params)
        .then(res => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Sukses',
                icon: 'CheckIcon',
                text: 'Data berhasil diupdate!',
                variant: 'success',
              },
            },
            2000,
          )
          this.getAllContact()
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Data gagal diupdate!',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    addSpace() {
      if (this.variableCount < this.maxVariableCount) {
        this.variableCount += 1
        this[`isHidden${this.variableCount}`] = false
      }
    },
    closeSpace() {
      if (this.variableCount > 0) {
        this[`isHidden${this.variableCount}`] = true
        this.variableCount -= 1
      }
    },
  },
}
</script>

  <style scoped>
  .icon-close {
    cursor: pointer;
  }
  </style>
