<template>
  <BModal
    id="modal-import-from-excel"
    ref="modal-import-from-excel"
    centered
    size="lg"
    :hide-footer="true"
    :hide-header="true"
    no-close-on-backdrop
  >
    <div class="mt-1 px-[5px] mb-2">
      <div class="">
        <div class="text-[#333]">
          <b class="text-xl">Upload File Excel</b>
        </div>
      </div>
      <hr class="my-2">
      <div
        :class="{'hidden': fileExcel }"
        class="border-[#AF6A13] border-[0.5px] p-[12px] rounded-lg bg-[#FFF2E2]"
      >
        <div class="flex justify-between items-center">
          <div class="text-[#AF6A13]">
            <span class="mr-[2px]"><b-icon
              icon="info-circle"
              variant="primary"
            /></span>
            Silahkan download template disamping sebelum import kontak
          </div>
          <div>
            <b-button
              size="sm"
              variant="primary"
              @click="handleDownloadTemplate"
            >
              Download Template
            </b-button>
          </div>
        </div>
      </div>
      <div
        id="drop-area"
        class="border-[#E2E2E2] grid justify-center border-[1px] p-[24px] my-1 rounded-lg bg-[#F4F4F4]"
        @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onDrop"
      >
        <div :class="{'bg-white w-[256px] h-[196px] p-[12px] rounded-lg grid items-center justify-center': fileExcel }">
          <div class="justify-center flex">
            <b-img
              height="69"
              width="69"
              src="https://storage.googleapis.com/komerce/assets/icons/upload-icon-grey.svg"
            />
          </div>
          <div :class="{'hidden': fileExcel }">
            <div class="font-[500] my-1 text-[#333333] text-[20px] text-center">
              Drag and Drop File Data Kontak
            </div>
            <div class="text-center text-[#828282] text-[14px]">
              Hanya file Excel dengan format .xlsx yang <br> diperbolehkan dengan ukuran file maksimum <span class="font-semibold">5 MB</span>
            </div>
          </div>
          <div
            v-if="fileExcel"
            class="grid text-center justify-center"
          >
            <div class="text-center  text-[14px]">
              {{ fileExcel.name }}
            </div>
            <div class="text-center text-[14px]">
              ( {{ `${formatBytes(fileExcel.size).size} ${formatBytes(fileExcel.size).type}` }} )
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-1">
          <label
            for="doc"
            class="flex w-full lg:w-[262px] items-center p-[6px] justify-center rounded-3xl border border-[#E2E2E2] bg-[#FFFFFF] cursor-pointer"
          >
            <div
              class="flex item-center"
              :class="{'hidden': fileExcel }"
            >
              <img
                class="flex items-center"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/document-upload.svg"
                alt=""
              >
              <div class="text-base ml-1 font-semibold flex items-center text-gray-700">Upload file</div>
              <input
                id="doc"
                type="file"
                name="doc"
                accept=".xlsx"
                hidden
                @change="handleFileUpload"
              >
            </div>
            <div
              class="text-base ml-1 font-semibold flex items-center text-gray-700"
              :class="{'hidden': !fileExcel }"
            >Ganti</div>
          </label>
        </div>
      </div>
      <hr class="mb-1 mt-2">
      <BRow class="justify-center">
        <BCol
          lg="8"
          class="flex gap-3 items-center justify-center"
        >
          <b-button
            block
            variant="outline-primary"
            class="mb-[-10px] !h-[37.6px]"
            @click="handleCancel"
          >
            Batalkan
          </b-button>
          <b-button
            block
            :variant="!fileExcel ? 'light' : 'primary'"
            class=""
            :disabled="fileExcel ? false : true"
            @click="handleActionUpload"
          >
            Import
          </b-button>
        </BCol>
      </BRow>
    </div>
  </BModal>
</template>

<script>
import { apiDownloadTemplateImportExcel } from '../Broadcast/service/api.groupContact.service'

export default {
  name: 'ModalImportContactFromExcel',
  emit: (['handleActionImport']),
  data() {
    return {
      fileExcel: null,
    }
  },
  methods: {
    onDragOver(event) {
      event.preventDefault()
      let target = event.target.style.border
      target = '2px dashed #000'
    },
    onDragLeave(event) {
      let target = event.target.style.border
      target = ''
    },
    onDrop(event) {
      event.preventDefault()
      const droppedFiles = event.dataTransfer.files[0]
      const fileExtension = droppedFiles.name.substr(droppedFiles.name.lastIndexOf('.') + 1)
      const { size } = droppedFiles
      if (fileExtension !== 'xlsx') {
        this.$toast_error({ message: 'Hanya dapat upload file dengan ekstensi .xlsx' })
      } else if (size >= 5000000) {
        this.$toast_error({ message: 'Hanya dapat upload file maksimal 5 Mb' })
      } else {
        this.fileExcel = droppedFiles
      }
    },
    handleCancel() {
      this.fileExcel = null
      this.$bvModal.hide('modal-import-from-excel')
    },
    formatBytes(bytes) {
      const kb = 1024
      const ndx = Math.floor(Math.log(bytes) / Math.log(kb))
      const fileSizeTypes = ['bytes', 'Kb', 'MB']

      const size = +(bytes / (kb ** ndx)).toFixed(2)

      return {
        size,
        type: fileSizeTypes[ndx],
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      const { name, size } = event.target.files[0]
      const extFile = name.substr(name.lastIndexOf('.') + 1)
      if (extFile !== 'xlsx') {
        this.$toast_error({ message: 'Hanya dapat upload file dengan ekstensi .xlsx' })
      } else if (size >= 5000000) {
        this.$toast_error({ message: 'Hanya dapat upload file maksimal 5 Mb' })
      } else {
        this.fileExcel = file
      }
    },
    handleActionUpload() {
      this.$emit('handleActionImport', this.fileExcel)
      this.handleCancel()
    },
    async handleDownloadTemplate() {
      try {
        const response = await apiDownloadTemplateImportExcel({
          responseType: 'arraybuffer',
        })

        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        const url = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'template_import_excel.xlsx')

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        window.URL.revokeObjectURL(url)

        this.$toast_success({ message: 'Template berhasil diunduh' })
      } catch (err) {
        this.$toast_error({ message: 'Gagal download template silahkan coba lagi' })
      }
    },
  },
}
</script>
